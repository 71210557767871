import React from 'react'
import Quiz from 'react-quiz-component'
import { Grid, Button, Typography } from '@mui/material'

const QuizPage = (props) => {
  const { module } = props
  console.log(module)
  return (
    <div>
      <Grid
        className="App-header"
        sx={{ backgroundColor: '#282c34', p: 3, pb: 8, color: '#FFF' }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2, mt: 5 }}>
          Member Quiz
        </Typography>
        <nav className="nav-bar">
          <a href="/admin/member-quiz/module1">
            <Button variant="contained" sx={{ mr: 2 }}>
              Module 1 Quiz
            </Button>
          </a>
          <a href="/admin/member-quiz/module2">
            <Button variant="contained" sx={{ mr: 2 }}>
              Module 2 Quiz
            </Button>
          </a>
          <a href="/admin/member-quiz/module3">
            <Button variant="contained">Module 3 Quiz</Button>
          </a>
        </nav>
      </Grid>
      <Grid sx={{ mt: 8, minHeight: '600px' }}>
        <Grid middle="true">
          <Grid>
            <Quiz quiz={module} shuffle />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default QuizPage
